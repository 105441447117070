import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import "./Leaderboard.css";
import { useNavigate } from "react-router-dom";

const Leaderboard = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState("");

  const getCurrentDateRange = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based month index

    // First day of the current month
    const startDate = new Date(currentYear, currentMonth, 1);

    // Last day of the current month
    const endDate = new Date(currentYear, currentMonth + 1, 0); // 0 gives the last day of the previous month

    // Format the dates as strings
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    return { startDate: formattedStartDate, endDate: formattedEndDate };
  };

  const getNextMonthStartDate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based month index

    // First day of the next month
    const nextMonthStart = new Date(currentYear, currentMonth + 1, 1);

    // Set the time to midnight
    nextMonthStart.setHours(0, 0, 0, 0);

    return nextMonthStart;
  };

  const calculateCountdown = () => {
    const nextMonthStart = getNextMonthStartDate();
    const now = new Date();
    const timeDiff = nextMonthStart - now;

    // Calculate the remaining days, hours, minutes, and seconds
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const getLeaderboard = async () => {
    const { startDate, endDate } = getCurrentDateRange();

    try {
      const res = await axios.get(
        `/api/user/leaderboard?startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data.success) {
        const emails = res.data.data;
        const updatedEmails = emails.map((item) => {
          if (item?.fname?.includes("@gmail.com")) {
            return {
              ...item,
              fname: item.fname.replace("@gmail.com", ""),
            };
          }
          return item;
        });
        setData(updatedEmails);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getLeaderboard();

    const countdownInterval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(countdownInterval); // Clear the interval on component unmount
  }, []);

  return (
    <Layout>
      <div className="leaderboard-container">
        <div className="leaderboard-content">
            <div className="leaderboard-top-content mb-4">
                <div className="box-1 center" onClick={()=>{navigate("/")}}>
                    <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"></path>
                    </svg>
                </div>
                <h2 className="mb-0 text-white">Leaderboard</h2>
            </div>
            <h4 className="heading text-white">🏆 Challenge ends in: {countdown}</h4>
            <div className="">
                {data && data?.length === 0 ? (
                    <div className="norecord">
                        <h5 className="m-0">🚀 No Records Found</h5>
                    </div>
                    ) : (
                    data?.map((item, index) => {
                        return (
                        <div
                            className={`items ${
                            index === 0 ? "top-1" : index === 1 ? "top-2" : index === 2 ? "top-3" : ""
                            }`}
                            key={index}
                        >
                            <span>
                            <p className="mb-0">{index + 1}</p>
                            {/* {item?.avatar ? (
                                <img src={item.avatar} alt="User Avatar" className="avatar" />
                            ) : (
                                <img src="/default-avatar.png" alt="Default Avatar" className="avatar" />
                            )} */}
                            {item?.fname}
                            </span>
                            <span>₹{item?.totalSpent}</span>
                        </div>
                        );
                    })
                )}
            </div>
        </div>
      </div>
    </Layout>
  );
};

export default Leaderboard;
