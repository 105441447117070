import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./Register.css";
import IMAGES from "../img/image";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/login", form);
      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        if (res.data.isAdmin) {
          navigate("/admin-dashboard");
        } else {
          navigate("/");
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="container-fluid login-container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-container">
              <form className="login-form" onSubmit={handleSubmit}>
                <h1>Sign In</h1>
                <div className="form-fields mb-4">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-fields mb-4">
                  <input
                    onChange={handleChange}
                    value={form?.password}
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    required
                  />
                </div>
                <button className="login-btn">Login</button>
                <div className="forgot-pass mt-3">
                  <div className="text-center">
                    <p>
                      New Customer? <Link to="/register">Sign Up</Link>
                    </p>
                    <p>
                      Forgot Password? <Link to="/forgot-password">Click Here</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
